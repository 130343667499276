import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Button } from '@mui/material';

import GoogleIcon from 'views/common/components/UI/Icons/GoogleIcon';
import FacebookIcon from 'views/common/components/UI/Icons/FacebookIcon';
import getConfig from 'next/config';
import dynamic from 'next/dynamic';
import axios, { API } from 'lib/config/axios';

import { setAppData } from 'redux/features/globalData-slice';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading, setSnackbar } from 'redux/features/ui-slice';
import useErrorsHandler from 'views/common/hooks/useErrorsHandler';
import { useTranslation } from 'next-i18next';
import useAppNavigator from 'views/common/hooks/useAppNavigator';
import PATHS from 'lib/navigation/paths';
import { FE } from 'lib/navigation/apps';
import clsx from 'clsx';
import useSSONavigate from 'views/common/hooks/useSSONavigate';

const PREFIX = 'SocialLogin';

const classes = {
  otherLoginBtn: `${PREFIX}-otherLoginBtn`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.otherLoginBtn}`]: {
    letterSpacing: theme.spacing(0.125),
    textTransform: 'uppercase',
  },
}));

const {
  publicRuntimeConfig: { envVars },
} = getConfig();

const WindowOpener = dynamic(() => import('lib/utils/WindowOpener'), {
  ssr: false,
});

const SocialLogin = (props) => {
  const { className } = props;
  const { t } = useTranslation('fe_er_auth');
  const dispatch = useDispatch();
  const handleErrorResponse = useErrorsHandler();
  const appNavigator = useAppNavigator();
  const appState = useSelector((state) => state.globalData.appState);
  const { moveToERSSOPage } = useSSONavigate();

  const randomString = Math.random().toString(36).substring(2, 15)
    + Math.random().toString(36).substring(2, 15);
  const [providerData, SetProviderData] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [stateCode, SetStateCode] = useState(randomString);

  // eslint-disable-next-line max-len
  const GoogleUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${envVars.GOOGLE_KEY}&response_type=code&scope=profile email&redirect_uri=${window.location.origin}/auth/google&state=${stateCode}`;
  const fbUrl = `https://www.facebook.com/v12.0/dialog/oauth?
client_id=${envVars.FB_KEY}
&redirect_uri=${window.location.origin}/auth/facebook&state=${stateCode}&scope=email,public_profile`;

  const handleSignIn = (authenticityToken) => {
    axios
      .post(API.users.thirdPartyAuth, {
        authenticity_token: authenticityToken,
        authorization_token: providerData.code,
        provider: providerData.provider,
      })
      .then((res) => {
        dispatch(
          setAppData({
            ...appState,
            ...res.data,
          }),
        );
        let path = null;
        if (appNavigator?.query?.return_url) {
          path = { url: appNavigator.query.return_url, app: FE };
        } else if (appNavigator.query.membership_id) {
          path = PATHS.motorcycleClub.checkout({ ...appNavigator.query });
        } else {
          path = PATHS.home();
        }

        moveToERSSOPage(
          res.data.otp_token,
          appNavigator.getUrl(undefined, path),
        );

        dispatch(setLoading(false));
      })
      .catch((error) => {
        try {
          switch (error.response.status) {
            case 401:
              dispatch(
                setSnackbar({
                  open: true,
                  severity: 'error',
                  message: error.response.data.message,
                }),
              );
              break;
            default:
              handleErrorResponse(error);
          }
        } catch (e) {
          handleErrorResponse(error);
        }
        dispatch(setLoading(false));
      });
  };

  const getToken = () => {
    dispatch(setLoading(true));
    axios
      .get(API.authenticityToken)
      .then((res) => {
        handleSignIn(res.data.authenticity_token);
      })
      .catch((error) => {
        handleErrorResponse(error);
        dispatch(setLoading(false));
      });
  };
  const providerResponse = (err, res) => {
    if (err) {
      // eslint-disable-next-line no-console
      console.log(res, 'error');
    }

    if (stateCode == res.state) {
      SetProviderData(res);
    } else {
      // eslint-disable-next-line no-alert
      alert('something wrong please try again ');
    }
  };
  useEffect(() => {
    providerData && getToken();
  }, [providerData]);
  return (
    <Root className={className}>
      <Box mb={2}>
        <WindowOpener
          url={GoogleUrl}
          bridge={providerResponse}
          width={600}
          height={600}>
          <Button
            className={clsx(classes.otherLoginBtn, 'SLGoogle')}
            fullWidth
            variant="outlined"
            color="secondary">
            <Box component="span" lineHeight="10px" mr={1}>
              <GoogleIcon />
            </Box>
            {t('fe_er_auth:login_with_google')}
          </Button>
        </WindowOpener>
      </Box>
      <Box mb={2}>
        <WindowOpener
          url={fbUrl}
          bridge={providerResponse}
          width={600}
          height={600}>
          <Button
            className={clsx(classes.otherLoginBtn, 'SLFacebook')}
            fullWidth
            variant="outlined"
            color="secondary">
            <Box component="span" lineHeight="10px" mr={1}>
              <FacebookIcon />
            </Box>
            {t('fe_er_auth:login_with_facebook')}
          </Button>
        </WindowOpener>
      </Box>
    </Root>
  );
};

export default SocialLogin;
