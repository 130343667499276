/* eslint-disable max-len */
import PATHS from 'lib/navigation/paths';
import getLocalizedUrl from 'lib/utils/getLocalizedUrl';
import useAppNavigator from 'views/common/hooks/useAppNavigator';

const useSSONavigate = () => {
  const appNavigator = useAppNavigator();
  const moveToERSSOPage = (token, returnURL = window.location.origin) => {
    const absoluteReturnUrl = returnURL.startsWith('http')
      ? returnURL
      : getLocalizedUrl(returnURL, appNavigator.locale);

    window.location.href = `${
      PATHS.eagleshare('').url
    }/sign_via_otp?otp_token=${token}&return_url=${decodeURIComponent(
      absoluteReturnUrl,
    )}`;
  };
  return { moveToERSSOPage };
};

export default useSSONavigate;
